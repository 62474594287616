.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navbar-style {
  background-color: rgb(0, 5, 31);
}

.navbar-text {
  padding-right: 2.5rem !important;
  color: white !important;
}

.nav-link
{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  color: white !important;
  font-weight: 700 !important;
}

.nav-link:hover,
.nav-link.active,
.nav-link:focus
{
    color: #fd810d !important;
}

.navbar-toggler {
  background-color: #fd810d !important;
}

.navbar-toggler:focus,
.navbar-toggler.active {
  background-color: #d66e0c !important;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#page-content {
  padding-top: 3rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.content-lr-margin {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.content-centered {
  align-items: center;
  text-align: center;
}

.home-background {
  background-image: url('home-image.webp'); /* Replace with your image URL */
  background-size: cover; /* Cover the entire div */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repetition */
  overflow: hidden; /* Ensures overlay does not extend beyond the div */
  height: 400px; /* Set height as needed */    
  position: relative;
  width: 100%; /* Set width as needed */
  border-radius: 16px;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 15, 100, 0.5); /* Change color and opacity here */
}

.spacer {
  padding: 1.2rem;
}

.title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
}

.overlay-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white !important;
  align-items: center;
  font-weight: 700;
}

.accent {
  color: #fd810d !important
}

.btn {
  background-color: #fd810d !important;
  border-color: #fd810d !important;
  color: white;
  font-weight: 600 !important;
}

.btn:hover {
  background-color: #d66e0c !important;
  border-color: #d66e0c !important;
  color: white;
  font-weight: 600;
}

.btn-light {
  background-color: white !important;
  border-color: white !important;
  color: white;
  font-weight: 600 !important;
}

.btn-light:hover {
  background-color: #ffb977 !important;
  border-color: #ffb977 !important;
  color: white;
  font-weight: 600 !important;
}


li::marker {
  color: #fd810d !important; /* Change bullet color */
  font-size: 1.1em; /* Adjust bullet size if needed */
}

li {
  font-size: 20px;
  font-weight: 700;
}
